import * as TYPES from "./appActionTypes";
import { post } from "../../http";

export const onAppBusy = value => ({
  type: TYPES.APP_BUSY,
  value
});

export function appBusy(value) {
  return { type: TYPES.APP_BUSY, value };
}

export function contactUs(params) {
  return (dispatch, getState) => {
    dispatch(appBusy(true));
    return post("https://osritapi.com/api/issues/contactus", params)
      .then(response => {
        return response;
      })
      .catch(err => {
        return err;
      })
      .then(response => {
        dispatch(appBusy(false));
        return response;
      });
  };
}
