import _get from "lodash/get";
import React, { Component } from "react";
import LOGO from "../images/logo.png";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { APP_URLS } from "../constants";
// import { contactUs } from "../actions/blog/blogActions";
import { contactUs } from "../actions/app/appActions";
// import { getHost } from "../constants";
// import RecaptchaVerify from "./RecaptchaVerify";
import { bindActionCreators } from "redux";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
    };
  }

  onSubmit = async (e, type) => {
    e.preventDefault();
    const { email, phone, agree } = this.state;
    this.setState({
      loading: true,
      error: "",
      success: "",
      successSms: "",
    });
    const response = await this.props.contactUs({
      app: "Subscribe",
      fullname: "Subscribe",
      email: email,
      phonenumber: phone,
      subject: agree ? "Subscribe" : "Not subscribe",
      message: agree ? "Subscribe" : "Not subscribe",
      dateadded: new Date(),
    });
    const isSms = type === "SMS";
    if (response && response.body && response.body.status === "success") {
      this.setState({
        loading: false,
        success: isSms ? "" : `Email is subscribed sucessfully.`,
        successSms: isSms ? `SMS consent is saved sucessfully.` : "",
      });
    } else if (response && response.errorCode === "ER_DUP_ENTRY") {
      this.setState({
        loading: false,
        error: `Email is already subscribed.`,
      });
    } else {
      this.setState({
        loading: false,
        error: `Something went wrong, please try after sometime.`,
      });
    }
  };

  render() {
    const { loading, error, success } = this.state;
    return (
      <div>
        <footer className="main-footer">
          <div className="auto-container">
            <div className="widgets-section">
              <div className="row clearfix">
                <div className="footer-column col-lg-4 col-md-6 col-sm-12">
                  <div className="footer-widget logo-widget">
                    <div className="logo">
                      <a href="/">
                        <img
                          src={LOGO}
                          alt="asritsolutions"
                          title="asritsolutions.com"
                        />
                        <span
                          style={{
                            color: "#222222",
                            fontFamily: "Dosis",
                            fontSize: 28,
                            verticalAlign: "middle",
                            fontWeight: 500,
                          }}
                        >
                          AsritSolutions
                        </span>
                      </a>
                    </div>
                    <div className="text" style={{ textAlign: "justify" }}>
                      We deliever awesome and well finished app and services.
                      Our team are highly motivated on various technology like
                      Web Development, Mobile Development, Search Engine
                      Optimization and Testing.
                    </div>
                    <ul className="social-icons">
                      <li className="facebook">
                        <a href={APP_URLS.FACEBOOK}>
                          <span className="fab fa-facebook-f ic" />
                        </a>
                      </li>
                      <li className="twitter">
                        <a href={APP_URLS.TWITTER}>
                          <span className="fab fa-twitter ic" />
                        </a>
                      </li>
                      <li className="linkedin">
                        <a href={APP_URLS.LINKED_IN}>
                          <span className="fab fa-linkedin-in ic" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="footer-column col-lg-3 col-md-6 col-sm-12">
                  <div className="footer-widget links-widget">
                    <h2>Quick Link</h2>
                    <div className="widget-content">
                      <ul className="list">
                        <li>
                          <Link to={"/"}>Home</Link>
                        </li>
                        <li>
                          <Link to={APP_URLS.SERVICE}>Services</Link>
                        </li>
                        <li>
                          <Link to={APP_URLS.PORTFOLIO}>Portfolio</Link>
                        </li>
                        <li>
                          <Link to={APP_URLS.CAREER}>Career</Link>
                        </li>
                        <li>
                          <Link to={APP_URLS.ABOUT}>About Us</Link>
                        </li>
                        <li>
                          <Link to={APP_URLS.CONTACT_US}>Contact Us</Link>
                        </li>
                        <li>
                          <a href={APP_URLS.TERMS}>Terms of Service</a>
                        </li>
                        <li>
                          <a href={APP_URLS.POLICY}>Privacy Policy</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="footer-column col-lg-5 col-md-12 col-sm-12">
                  <div className="footer-widget newsletter-widget">
                    <h2 style={{ paddingBottom: 0, marginBottom: 0 }}>
                      Newsletter
                    </h2>
                    <div
                      style={{ paddingBottom: 0, marginBottom: 0 }}
                      className="text"
                    >
                      Subscribe to our newsletter and stay updates for all
                      technology, offer, jobs alert!
                    </div>
                    <div className="newsletter-form">
                      <form onSubmit={this.onSubmit}>
                        <div
                          style={{
                            color: "red",
                            marginTop: 10,
                            textAlign: "center",
                          }}
                        >
                          {error}
                        </div>
                        <div
                          style={{
                            marginTop: 10,
                            textAlign: "center",
                            color: "green",
                          }}
                        >
                          {success}
                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Your email"
                            required
                            disabled={loading}
                            onChange={(e) =>
                              this.setState({
                                email: e.target.value,
                                success: "",
                                error: "",
                              })
                            }
                          />

                          <button
                            onClick={(e) => this.onSubmit(e, "EMAIL")}
                            className="theme-btn btn-style-four"
                          >
                            <span className="txt">
                              {loading ? "Please wait" : "Subscribe"}
                            </span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom style-four">
            <div className="auto-container">
              <div className="clearfix">
                <div className="pull-left">
                  <div className="copyright">
                    AsritSolutions &copy; 2020 All Right Reserved
                  </div>
                </div>
                <div className="pull-right">
                  <ul className="footer-list">
                    <li>
                      <a href={APP_URLS.TERMS}>Terms of Service</a>
                    </li>
                    <li>
                      <a href={APP_URLS.POLICY}>Privacy Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    blogs: _get(state, "blogData.blogs", []),
    count: _get(state, "blogData.count", 0),
    categories: _get(state, "blogData.categories", []),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ contactUs }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
