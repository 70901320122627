import React, { Component } from "react";
class ScroollBar extends Component {
  async componentDidMount() {
  }

  render() {
    return (
      <div>
        <div
          onClick={() => {
            window.scrollTo(0, 0)
          }}
          className="scroll-to-top scroll-to-target"
          data-target="html"><span className="fa fa-angle-up"></span></div>

        <div id="search-popup" className="search-popup">
          <div className="close-search theme-btn"><span className="flaticon-cancel"></span></div>
          <div className="popup-inner">
            <div className="overlay-layer"></div>
            <div className="search-form">
              <form method="post" action="/">
                <div className="form-group">
                  <fieldset>
                    <input type="search" className="form-control" name="search-input"   placeholder="Search Here" required />
                    <input type="submit" value="Search Now!" className="theme-btn" />
                  </fieldset>
                </div>
              </form>

              <br />
              <h3>Recent Search Keywords</h3>
              <ul className="recent-searches">
                <li><a href="/q">Finance</a></li>
                <li><a href="/q">Idea</a></li>
                <li><a href="/q">Service</a></li>
                <li><a href="/q">Growth</a></li>
                <li><a href="/q">Plan</a></li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ScroollBar;