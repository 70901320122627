import _get from "lodash/get";
import React, { Component } from "react";
// import LOGO from "../images/logo.png";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
// import { APP_URLS } from "../constants";
// import { contactUs } from "../actions/blog/blogActions";
import { contactUs } from "../actions/app/appActions";
// import { getHost } from "../constants";
// import RecaptchaVerify from "./RecaptchaVerify";
import { bindActionCreators } from "redux";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      success: "",
      email: "",
    };
  }

  onSubmit = async (e, type) => {
    e.preventDefault();
    const { email, phone, agree } = this.state;
    this.setState({
      loading: true,
      error: "",
      success: "",
      successSms: "",
    });
    const response = await this.props.contactUs({
      app: "Subscribe",
      fullname: "Subscribe",
      email: email,
      phonenumber: phone,
      subject: agree ? "Subscribe" : "Not subscribe",
      message: agree ? "Subscribe" : "Not subscribe",
      dateadded: new Date(),
    });
    const isSms = type === "SMS";
    if (response && response.body && response.body.status === "success") {
      this.setState({
        loading: false,
        success: isSms ? "" : `Email is subscribed sucessfully.`,
        successSms: isSms ? `SMS consent is saved sucessfully.` : "",
      });
    } else if (response && response.errorCode === "ER_DUP_ENTRY") {
      this.setState({
        loading: false,
        error: `Email is already subscribed.`,
      });
    } else {
      this.setState({
        loading: false,
        error: `Something went wrong, please try after sometime.`,
      });
    }
  };

  render() {
    const { loading, error, successSms } = this.state;
    return (
      <div style={{ padding: "1px 25px 45px 25px" }}>
        <div className="col-md-12 col-sm-12">
          <div
            className="newsletter-form"
            style={{
              width: "100%",
              maxWidth: 600,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <h2 style={{ paddingBottom: 0, marginBottom: 10 }}>Sms Opt-In</h2>
            <form>
              <div className="form-group">
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone number"
                  required
                  disabled={loading}
                  onChange={(e) =>
                    this.setState({
                      phone: e.target.value,
                      success: "",
                      error: "",
                    })
                  }
                />
              </div>
              <div
                className="form-group"
                style={{
                  marginTop: 20,
                }}
              >
                <input
                  type="checkbox"
                  id="agree"
                  name="agree"
                  value="agree"
                  onChange={(e) => {
                    this.setState({
                      agree: e.target.checked,
                      success: "",
                      error: "",
                    });
                  }}
                />
                <label
                  htmlFor="privacyPolicy"
                  style={{ marginLeft: 20, marginTop: -18 }}
                >
                  By checking this box, you agree to receive SMS messages from
                  Asritsolutions LLC at the number provided. Message and data
                  rates may apply. Reply STOP to unsubscribe. View our <a href="/policy">privacy policy</a>.
                </label>
              </div>
              <div
                style={{
                  color: "red",
                  marginTop: 10,
                  textAlign: "left",
                }}
              >
                {error}
              </div>
              <div
                style={{
                  marginTop: 10,
                  textAlign: "left",
                  color: "green",
                }}
              >
                {successSms}
              </div>
              <div>
                <button
                  style={{
                    marginTop: 10,
                  }}
                  disabled={!this.state.phone}
                  onClick={(e) => this.onSubmit(e, "SMS")}
                  className="theme-btn btn-style-four"
                >
                  <span className="txt">
                    {loading ? "Please wait" : "Submit"}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    blogs: _get(state, "blogData.blogs", []),
    count: _get(state, "blogData.count", 0),
    categories: _get(state, "blogData.categories", []),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ contactUs }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
