import * as TYPES from "./blogActionTypes";

const defaultState = {
    count: 0,
    loading: false,
    categories: [],
    blogs: [],
    blogSelected: {}
};

export default function blog(state = defaultState, action) {
    switch (action.type) {
        case TYPES.BLOG_BUSY:
            return {
                ...state,
                loading: action.value
            };
        case TYPES.BLOG_COUNT:
            return {
                ...state,
                count: action.value
            };
        case TYPES.BLOG_CATEGORIES:
            return {
                ...state,
                categories: action.value
            };
        case TYPES.BLOGS:
            return {
                ...state,
                blogs: action.value
            };
        case TYPES.BLOG_SELECTED:
            return {
                ...state,
                blogSelected: action.value
            };
        default:
            return state;
    }
}
