export const preloadedState = {
    blogData: {
        count: 0
        // categories: [
        //     { id: 1, label: "ALL" },
        //     { id: 2, label: "DEVELOPMENT" },
        //     { id: 3, label: "TESTING" },
        //     { id: 4, label: "DEV OPS" },
        //     { id: 5, label: "CLOUD" },
        //     { id: 6, label: "ELD" },
        //     { id: 7, label: "TRUCKING" },
        //     { id: 8, label: "COMPLIANCE" }
        // ],
        // blogs: [
        //     {
        //         id: "1",
        //         category: "Information Technology",
        //         categoryDetail: {
        //             title: "Information Technology",
        //             textColor: "white",
        //             backgroundColor: "#007bff"
        //         },
        //         slug: "schedule-cron-jobs-google-cf",
        //         title: "How to Schedule Cron Jobs with Google Cloud Functions?",
        //         author: {
        //             name: "Maninder",
        //             date: "November 19, 2018"
        //         },
        //         imageUrl:
        //             "https://www.qsstechnosoft.com/wp-content/uploads/2018/11/googleCloud2-1-1.png",
        //         header:
        //             "This article is more about building a real time, fast, scalable, time-based and event driven application with the help of app engine cron services and cloud functions. This article is more about building a real time, fast, scalable, time-based and event driven application with the help of app engine cron services and cloud functions."
        //     },
        //     {
        //         id: "2",
        //         category: "Information Technology",
        //         categoryDetail: {
        //             title: "Information Technology",
        //             textColor: "white",
        //             backgroundColor: "#007bff"
        //         },
        //         slug: "effective-agile-development-agile",
        //         title: "How effective is Agile Software Development?",
        //         author: {
        //             name: "Admin",
        //             date: "November 19, 2018"
        //         },
        //         imageUrl:
        //             "https://www.qsstechnosoft.com/wp-content/uploads/2019/11/Agile-Methodology-1200x630.png",
        //         header:
        //             "The Agile Methodology is a term used for an incremental and iterative software development approach to control and incorporate continuously moving requirements."
        //     },
        //     {
        //         id: "3",
        //         category: "Information Technology",
        //         categoryDetail: {
        //             title: "Information Technology",
        //             textColor: "white",
        //             backgroundColor: "#007bff"
        //         },
        //         slug: "node-js-docker-prod",
        //         title: "Multi-Stage Nodejs Build with Docker in Production",
        //         imageUrl:
        //             "https://www.qsstechnosoft.com/wp-content/uploads/2018/11/Multi-Stage-Nodejs-Build-with-Docker-1-1-1.png",
        //         header:
        //             "This article targets folks/teams who deploy their applications with Vagrant (VMs) or without any kind of container or images or virtualization but would like to switch to Docker.",
        //         author: {
        //             name: "Admin",
        //             date: "October 15, 2018"
        //         }
        //     },
        //     {
        //         id: "4",
        //         category: "Information Technology",
        //         categoryDetail: {
        //             title: "Information Technology",
        //             textColor: "white",
        //             backgroundColor: "#007bff"
        //         },
        //         slug: "google-map-leaflet",
        //         title: "How to use Google Maps in Leaflet?",
        //         imageUrl:
        //             "https://www.qsstechnosoft.com/wp-content/uploads/2018/11/Leaflet-1-1.jpg",
        //         header:
        //             "This article helps the user to render the map on the page using Leaflet. Using the leaflet, we can render the map into an HTML element and we can set the marker on the map.",
        //         author: {
        //             name: "Robert",
        //             date: "October 01, 2018"
        //         }
        //     }
        // ],
        // blogSelected: {
        //     slug: "schedule-cron-jobs-google-cf",
        //     category: "Information Technology",
        //     categoryShort: "IT",
        //     subCategory: "cloudFunction",
        //     subCategoryShort: "CF",
        //     title: "How to Schedule Cron Jobs with Google Cloud Functions?",
        //     status: "PUBLISH",
        //     created: "November 19, 2018",
        //     updated: "November 19, 2018",
        //     imageUrl:
        //         "https://www.qsstechnosoft.com/wp-content/uploads/2018/11/googleCloud2-1-1.png",
        //     tags: ["maps", "google", "leaflet"],
        //     author: {
        //         name: "Robert",
        //         date: "October 01, 2018"
        //     },
        //     header:
        //         "Abstract This article is more about building a real time, fast, scalable, time-based and event driven application with the help of app engine cron services and cloud functions. We will explore how cron jobs and cloud functions can be so helpful sometimes. Introduction What are cron jobs ? Cron…",
        //     description: [
        //         {
        //             title: "Abstract",
        //             text:
        //                 "This article is more about building a real time, fast, scalable, time-based and event driven application with the help of app engine cron services and cloud functions. We will explore how cron jobs and cloud functions can be so helpful sometimes.",
        //             imageUrl: ""
        //         },
        //         {
        //             title: "What are cron jobs ?",
        //             text:
        //                 "Cron jobs are time-based jobs which are programmed to run on a specific time interval. App Engine cron service automatically triggers the cron jobs that operate at a regular interval or defined time. App Engine cron service will invoke a URL by using a HTTP get request.",
        //             imageUrl: ""
        //         },
        //         {
        //             title: "What are Google Cloud Functions ?",
        //             text:
        //                 "Google cloud functions are server less compute solution which are used to create event-driven applications. It is a joint product of Google cloud platform and firebase team. Firebase is a development platform for mobile and web applications, founded in 2011 and acquired by Google in 2014.",
        //             imageUrl: "",
        //             listTitle:
        //                 "Following things can be done with cloud functions.",
        //             listItems: [
        //                 {
        //                     title: "",
        //                     text:
        //                         "It can be used for notification when a certain event triggers.",
        //                     imageUrl: ""
        //                 },
        //                 {
        //                     title: "",
        //                     text:
        //                         "It can be used for Real-time Database which syncs data across all the clients in real-time.",
        //                     imageUrl: ""
        //                 },
        //                 {
        //                     title: "",
        //                     text:
        //                         "It can be used to perform the intensive task at cloud instead of in your own application. It’s kind of help in computation which makes the application run more faster.",
        //                     imageUrl: ""
        //                 },
        //                 {
        //                     title: "",
        //                     text: "It can be integrated with third-party APIs.",
        //                     imageUrl: ""
        //                 }
        //             ]
        //         }
        //     ]
        // }
    }
};
