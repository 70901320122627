import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import SmsOptIn from "./components/SmsOptIn";
import TermsOfService from "./components/TermsOfService";
import PrivacyPolicy from "./components/PrivacyPolicy";
import SideBar from "./components/SideBar";
import ScroollBar from "./components/ScrollBar";
import { APP_URLS } from "./constants";

const ContactUs = React.lazy(() => import("./views/ContactUs"));
const About = React.lazy(() => import("./views/About"));
const Portfolios = React.lazy(() => import("./views/Portfolios"));
const PortfolioDetails = React.lazy(() => import("./views/PortfolioDetails"));
const Services = React.lazy(() => import("./views/Services"));
const Home = React.lazy(() => import("./views/Home"));
const Team = React.lazy(() => import("./views/Team"));
const Faqs = React.lazy(() => import("./views/Faqs"));
const Pricing = React.lazy(() => import("./views/Pricing"));
const ServiceDetails = React.lazy(() => import("./views/ServiceDetails"));
const Career = React.lazy(() => import("./views/Career/Career"));


const loading = () => (
    <div className="page-wrapper">
        <div className="preloader" />
    </div>
);
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarOpen: false,
            xx: "",
            mobileClass: ""
        };
    }
    close = () => {
        this.setState({ xx: "" });
    };

    render() {
        return (
            <Router>
                <React.Suspense fallback={loading()}>
                    <div className={"page-wrapper " + this.state.mobileClass}>
                        {/* <div className="preloader"></div> */}

                        <Header
                            onMenuClick={() => {
                                this.setState({
                                    mobileClass: "mobile-menu-visible"
                                });
                            }}
                            onMenuClose={() => {
                                this.setState({ mobileClass: "" });
                            }}
                            openSlideBar={() => {
                                this.setState({ xx: "isActive" });
                            }}
                        />

                        <Switch>
                            <Route
                                exact
                                path={APP_URLS.HOME}
                                name="Home"
                                render={props => <Home />}
                            />

                            <Route
                                exact
                                path={APP_URLS.SERVICE}
                                name="Services"
                                render={props => <Services />}
                            />

                            <Route
                                exact
                                path={APP_URLS.PORTFOLIO}
                                name="Portfolio"
                                render={props => <Portfolios />}
                            />
                            <Route
                                exact
                                path={APP_URLS.PORTFOLIO_DETAIL}
                                name="PortfolioDetails"
                                render={props => <PortfolioDetails />}
                            />
                            <Route
                                exact
                                path={APP_URLS.CAREER}
                                name="Career"
                                render={props => <Career />}
                            />
                            <Route
                                exact
                                path={APP_URLS.ABOUT}
                                name="About"
                                render={props => <About />}
                            />
                            <Route
                                exact
                                path={APP_URLS.CONTACT_US}
                                name="Contact Us"
                                render={props => <ContactUs />}
                            />

                            <Route
                                exact
                                path={APP_URLS.TEAM}
                                name="Team"
                                render={props => <Team />}
                            />

                            <Route
                                exact
                                path={APP_URLS.FAQ}
                                name="Faqs"
                                render={props => <Faqs />}
                            />

                            <Route
                                exact
                                path={APP_URLS.PRICING}
                                name="Pricing"
                                render={props => <Pricing />}
                            />

                            <Route
                                exact
                                path={APP_URLS.SERVICE_DETAIL}
                                name="ServiceDetails"
                                render={props => <ServiceDetails />}
                            />

                            <Route
                                exact
                                path={APP_URLS.TERMS}
                                name="TermsOfService"
                                render={props => <TermsOfService />}
                            />
                            <Route
                                exact
                                path={APP_URLS.POLICY}
                                name="PrivacyPolicy"
                                render={props => <PrivacyPolicy />}
                            />
                        </Switch>
                        <SmsOptIn />
                        <Footer />
                    </div>
                    <SideBar cc={this.close} xx={this.state.xx} />
                    <ScroollBar />
                </React.Suspense>
            </Router>
        );
    }
}

export default App;
