import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./css/bootstrap.css";
import "./css/style.css";
import "./css/responsive.css";
import App from "./App";
import { Provider } from "react-redux";
import configureStore from "./configureStore";
import * as serviceWorker from "./serviceWorker";
import { preloadedState } from "./preloadedState";

const store = configureStore(preloadedState);

class AppBootstrap extends Component {
    render() {
        return (
            <Provider store={store}>
                <App />
            </Provider>
        );
    }
}

ReactDOM.render(<AppBootstrap />, document.getElementById("root"));

serviceWorker.unregister();
