import React, { Component } from "react";
import { Link } from "react-router-dom";
import LOGO from "../images/logo.png";
import { APP_URLS } from "../constants";


class Header extends Component {
    async componentDidMount() { }

    render() {
        return (
            <header className="main-header header-style-three">
                <div className="header-upper">
                    <div className="outer-container">
                        <div className="clearfix">
                            <div className="logo-outer">
                                <div className="logo">
                                    <a href="/">
                                        <img
                                            src={LOGO}
                                            alt="asritsolutions"
                                            title="asritsolutions.com"
                                        />
                                        <span
                                            style={{
                                                color: "white",
                                                fontFamily: "Dosis",
                                                fontSize: 30,
                                                verticalAlign: "middle",
                                                fontWeight: 600
                                            }}
                                        >
                                            AsritSolutions
                                        </span>
                                    </a>
                                </div>
                            </div>

                            <div className="nav-outer clearfix">
                                <div
                                    className="mobile-nav-toggler  default-mobile-nav-toggler"
                                    onClick={this.props.onMenuClick}
                                >
                                    <span className="icon flaticon-menu" />
                                </div>
                                <nav className="main-menu navbar-expand-md navbar-light">
                                    <div className="navbar-header">
                                        <button
                                            className="navbar-toggler"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target="#navbarSupportedContent"
                                            aria-controls="navbarSupportedContent"
                                            aria-expanded="false"
                                            aria-label="Toggle navigation"
                                        >
                                            <span className="icon flaticon-menu" />
                                        </button>
                                    </div>

                                    <div
                                        className="collapse navbar-collapse clearfix"
                                        id="navbarSupportedContent"
                                    >
                                        <ul className="navigation clearfix">
                                            <li>
                                                <Link to={`/`}>Home</Link>
                                            </li>
                                            <li className="dropdown">
                                                <Link to={`/services`}>Services</Link>
                                                <ul>
                                                    <li>
                                                        <Link to={APP_URLS.SERVICE_DETAIL + '?service=web'}>Web Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to={APP_URLS.SERVICE_DETAIL + '?service=mobile'}>Mobile Application Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to={APP_URLS.SERVICE_DETAIL + '?service=ecommerce'}>E-commerce App Development</Link>
                                                    </li>
                                                    <li>
                                                        <Link to={APP_URLS.SERVICE_DETAIL + '?service=cloud'}>Cloud Services</Link>
                                                    </li>
                                                    <li>
                                                        <Link to={APP_URLS.SERVICE_DETAIL + '?service=uiux'}>UI/UX Design</Link>
                                                    </li>
                                                    <li>
                                                        <Link to={APP_URLS.SERVICE_DETAIL + '?service=qa'}>QA & Testing</Link>
                                                    </li>
                                                    <li>
                                                        <Link to={APP_URLS.SERVICE_DETAIL + '?service=support'}>Support & Maintenance</Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to={APP_URLS.PORTFOLIO}>Portfolios</Link>
                                            </li>
                                            <li>
                                                <Link to={APP_URLS.CAREER}>Career</Link>
                                            </li>
                                            <li>
                                                <Link to={APP_URLS.ABOUT}>About</Link>
                                            </li>

                                            <li>
                                                <Link to={APP_URLS.CONTACT_US}>Contact</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>

                                <div className="outer-box clearfix">
                                    <div className="nav-box">
                                        <div
                                            onClick={this.props.openSlideBar}
                                            className="nav-btn nav-toggler navSidebar-button clearfix"
                                        >
                                            <span className="icon" />
                                            <span className="icon" />
                                            <span className="icon" />
                                        </div>
                                    </div>

                                    {/* <div className="search-box-btn"><span className="icon flaticon-magnifying-glass-1"></span></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Mobile menu */}
                <div className="mobile-menu">
                    <div
                        className="menu-backdrop"
                        onClick={this.props.onMenuClose}
                    />
                    <div className="close-btn" onClick={this.props.onMenuClose}>
                        <span className="icon flaticon-cancel" />
                    </div>
                    <nav className="menu-box mCustomScrollbar _mCS_1">
                        <div
                            id="mCSB_1"
                            className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                            tabIndex="0"
                            style={{ maxHeight: 440 }}
                        >
                            <div
                                id="mCSB_1_container"
                                className="mCSB_container"
                                style={{
                                    position: "relative",
                                    top: 0,
                                    left: 0
                                }}
                                dir="ltr"
                            >
                                <div className="nav-logo">
                                    <a href="index.html">
                                        <img
                                            src="images/logo.png"
                                            alt=""
                                            title=""
                                            className="mCS_img_loaded"
                                        />
                                        <span
                                            style={{
                                                color: "white",
                                                fontFamily: "Dosis",
                                                fontSize: 20,
                                                verticalAlign: "middle",
                                                fontWeight: 600
                                            }}
                                        >
                                            AsritSolutions
                                        </span>
                                    </a>
                                </div>
                                <div className="menu-outer">
                                    <ul className="navigation clearfix">
                                        {/* <li className="current dropdown">   */}
                                        <li>
                                            <Link to={`/`} onClick={this.props.onMenuClose}>Home</Link>
                                        </li>

                                        <li className="dropdown">
                                            <Link to={APP_URLS.SERVICE} onClick={this.props.onMenuClose}>Services</Link>

                                            <ul>
                                                <li>
                                                    <a href="ServiceDetails">
                                                        Web Development
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="ServiceDetails">
                                                        Mobile Application
                                                        Development
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="ServiceDetails">
                                                        E-commerce App
                                                        Development
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="ServiceDetails">
                                                        Cloud Services
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="ServiceDetails">
                                                        UI/UX Design
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="ServiceDetails">
                                                        QA & Testing
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="ServiceDetails">
                                                        Support & Maintenance
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <Link to={APP_URLS.PORTFOLIO} onClick={this.props.onMenuClose}>Portfolio</Link>
                                        </li>
                                        <li>
                                            <Link to={APP_URLS.CAREER} onClick={this.props.onMenuClose}>Career</Link>
                                        </li>
                                        <li>
                                            <Link to={APP_URLS.ABOUT} onClick={this.props.onMenuClose}>About</Link>
                                        </li>
                                        <li>
                                            <Link to={APP_URLS.CONTACT_US} onClick={this.props.onMenuClose}>Contact</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="social-links">
                                    <ul className="clearfix">
                                        <li>
                                            <a href={APP_URLS.FACEBOOK}>
                                                <span className="fab fa-facebook-square" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={APP_URLS.TWITTER}>
                                                <span className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={APP_URLS.PINTEREST}>
                                                <span className="fab fa-pinterest-p" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href={APP_URLS.INSTA}>
                                                <span className="fab fa-instagram" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div
                                id="mCSB_1_scrollbar_vertical"
                                className="mCSB_scrollTools mCSB_1_scrollbar mCS-light mCSB_scrollTools_vertical"
                                style={{ display: "block" }}
                            >
                                <div className="mCSB_draggerContainer">
                                    <div
                                        id="mCSB_1_dragger_vertical"
                                        className="mCSB_dragger"
                                        style={{
                                            position: "absolute",
                                            minHeight: 30,
                                            height: 365,
                                            top: 0,
                                            display: "block",
                                            maxHeight: 414
                                        }}
                                        onContextMenu={() => false}
                                    >
                                        <div
                                            className="mCSB_dragger_bar"
                                            style={{ lineHeight: 30 }}
                                        />
                                    </div>
                                    <div className="mCSB_draggerRail" />
                                </div>
                            </div>
                        </div>
                    </nav>{" "}
                </div>
            </header>
        );
    }
}
export default Header;
