const URLS = {
    BLOG_API: process.env.REACT_APP_BLOG_API
};
const APP_URLS = {
    HOME: "/",
    SERVICE: "/services",
    PORTFOLIO: "/portfolio",
    PORTFOLIO_DETAIL: "/portfolio-detail",
    CAREER: "/career",
    ABOUT: "/about",
    CONTACT_US: "/contact-us",
    BLOG: "https://blog.asritsolutions.com",
    TEAM: "/team",
    FAQ: "/faq",
    PRICING: "/pricing",
    SERVICE_DETAIL: "/service-detail",
    TERMS: "/terms",
    POLICY: "/policy",

    TWITTER: "//twitter.com/AsritSolutions",
    FACEBOOK: "//www.facebook.com/Asrit-Solutions-109605270772476/",
    PINTEREST: "//www.pinterest.com/Asrit_Solutions/",
    REDDIT: "//www.reddit.com/user/AsritSolutions/",
    INSTA: "//www.instagram.com/",
    LINKED_IN: "//www.linkedin.com/"
};

export function getHost() {
    const url = window.location.hostname;
    const _hostname = url.split(".").slice(-2);
    const hostname = _hostname[0] || "";

    return hostname === "asritsolutions" ? "TRUCKING" : "IT";
}

export { URLS, APP_URLS };
